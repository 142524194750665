import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css({
        background: variables.dpc_backgroundWhite,

        '.layout-container': {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'stretch',

            '.register-form-container': {
                width: '45%',
                padding: '2em',
                paddingTop: '6em',
                minHeight: '85vh',               
            },
            '.checkout-container': {
                color: variables.dpc_fontDark,
                width: '55%',
                padding: '2em',
                minHeight: '85vh',

                '.page-title': {
                    fontSize: '3em',
                    fontWeight: 600,
                    marginBottom: '.5em',
                },

                '.checkout-summary': {
                    padding: '2em',
                    border: '3px solid rgb(184 24 24)',
                    boxShadow: '0.00px 0.00px 4px 0px rgba(0,0,0,0.6)',
                },

                '.summary-line-trainer': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '1em',
                    marginBottom: '1em',
                    borderBottom: '1px solid #666666',

                    '.trainer-profile': {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    
                        '.trainer-avatar': {
                            width: '10em',
                            height: '10em',
                            borderRadius: '36em',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            border: '.2em solid black',
                        },
                        '.trainer-details': {
                            paddingLeft: '1em',

                            '.trainer-name': {
                                fontSize: '1.6em',
                                fontWeight: 600,
                            },
                            '.trainer-description': {
                                fontSize: '.9em',
                                fontWeight: 300,
                            },
                        },
                    },
                    '.summary-line-price': {
                        fontSize: '1.6em',
                        fontWeight: 600,
                        paddingLeft: '1em',
                    },
                },
                '.summary-line': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '1em',
                    marginBottom: '1em',
                    borderBottom: '1px solid #666666',

                    '.summary-line-label': {
                        color: variables.dpc_fontDark,
                        fontWeight: 300,
                        fontSize: '1.1em',
                    },
                    '.summary-line-value': {
                        fontWeight: 600,
                        fontSize: '1.5em',
                    },
                },
                '.summary-line-total': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',

                    '.summary-line-label': {
                        fontWeight: 300,
                        fontSize: '1.4em',
                    },
                    '.summary-line-value': {
                        fontWeight: 600,
                        fontSize: '2.4em',
                    },
                },
                '.summary-line-discailer': {
                    color: '#666666',
                    fontSize: '.9em',
                    textAlign: 'left',
                    padding: '1em',
                },
            },
        },
    });
