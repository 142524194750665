import { css } from '@emotion/core';

export default () =>
    css({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',

        '.form-divider': {
            margin: '2em 0',
        },
        '.submit-button': {
            marginTop: '1.5em',
        },
    });
