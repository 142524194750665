import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LEAD_SOURCE_PREFERENCE_SURVEY, LEAD_MEDIUM_LP } from 'Consts/leads';

import { parseQueryToObject } from 'Utils/querystring';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';

export default class SurveyCheckoutForm extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        location: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            register: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {
        onSuccess: null,
    };
    state = {
        globalError: null,
        errors: {},
        isPending: false,
        formState: {
            email: null,
            password: null,
            name: null,
            surname: null,
            address: null,
            phone: null,
            postalCode: null,
            city: null,
        },
    };

    onSubmit = () => {
        const { actions, location } = this.props;
        const { formState } = this.state;

        const queryObject = parseQueryToObject(location.search);

        this.setState({
            isPending: true,
            errors: {},
            globalError: null,
        });

        return actions.register({
            ...formState,
            medium: LEAD_MEDIUM_LP,
            source: LEAD_SOURCE_PREFERENCE_SURVEY,
        })
            .then(response => {
                this.setState({ isPending: false });
                this.onSuccess(response);
            })
            .catch(error => {
                Logger.error('[REGISTER_ADEPT_FORM] Error', error);
                this.setState({
                    isPending: false,
                    errors: error.payload.validationErrors,
                    globalError: error.payload.message,
                });
            });
    }

    onSuccess = response => {
        const { onSuccess } = this.props;

        if (onSuccess) {
            return onSuccess(response);
        }

        return null;
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    render() {
        const { formState, globalError, errors, isPending } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="component-adept-regsiter-form"
            >
                <Form
                    data={formState}
                    onStateChange={this.onStateChange}
                    errors={errors}
                    globalError={globalError}
                    onSubmit={this.onSubmit}
                    isPending={isPending}
                >
                    <Input
                        label="E-mail"
                        name="email"
                        placeholder="Wprowadź adres email"
                        type="email"
                        required
                    />
                    <Input
                        label="Hasło"
                        name="password"
                        placeholder="Wprowadź hasło"
                        type="password"
                        required
                    />
                    <hr className="form-divider" />
                    <Input
                        label="Imię"
                        name="name"
                        placeholder="Wprowadź imię"
                        required
                    />
                    <Input
                        label="Nazwisko"
                        name="surname"
                        placeholder="Wprowadź nazwisko"
                        required
                    />
                    <Input
                        label="Numer telefonu"
                        name="phone"
                        placeholder="Wprowadź numer telefonu"
                        required
                    />
                    <Input
                        label="Adres"
                        name="address"
                        placeholder="Wprowadź adres"
                        required
                    />
                    <Input
                        label="Kod pocztowy"
                        name="postalCode"
                        placeholder="Wprowadź kod pocztowy"
                        required
                    />
                    <Input
                        label="Miasto"
                        name="city"
                        placeholder="Wprowadź miasto"
                        required
                    />
                    <Button
                        className="submit-button"
                        type="submit"
                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                        layout="fullWidth"
                        size="large"
                    >
                        Zaczynamy!
                    </Button>
                </Form>
            </StyledComponent>
        );
    }
}
