import React, { Component } from 'react';
import PropTypes from 'prop-types';
import base64 from 'base-64';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Head from 'Components/core/Head';
import SurveyCheckoutForm from 'Components/public/survey/SurveyCheckoutForm';

export default class PublicSurveyCheckouy extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    onSuccess = response => {
        //TODO: WTF?
        window.location.href = 'http://localhost:3001/koszyk/58e819d7-1f74-47be-be02-997f8be47182?ud=' + base64.encode(JSON.stringify(response.payload.me));
    }

    render() {
        const { location } = this.props;

        return (
            <StyledComponent
                className="component-survey-checkout-page"
                styles={require('./styles')}
            >
                <Head
                    title="meta.title"
                    description="meta.description"
                />
                <LayoutContainer>
                    <div className="checkout-container">
                        <h1 className="page-title">Podsumowanie</h1>
                        <div className="checkout-summary">
                            <div className="summary-line-trainer">
                                <div className="trainer-profile">
                                    <div
                                        className="trainer-avatar"
                                        style={{ backgroundImage: `url(${require('Theme/images/placeholders/trainer-bg.png')})` }}
                                    />
                                    <div className="trainer-details">
                                        <p className="trainer-name">
                                            Michał
                                        </p>
                                        <p className="trainer-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br />
                                            Morbi consequat blandit enim fermentum ultricies.<br />
                                        </p>
                                    </div>
                                </div>
                                <p className="summary-line-price">99zł</p>
                            </div>
                            <div className="summary-line">
                                <p className="summary-line-label">Konsultacja dietetyczna</p>
                                <p className="summary-line-value">GRATIS</p>
                            </div>

                            <div className="summary-line-total">
                                <p className="summary-line-label">Zacznij od dzisiaj</p>
                                <p className="summary-line-value">99zł</p>
                            </div>
                        </div>
                        <div className="summary-line-discailer">
                            99zł brutto miesięcznie.<br />
                            Subskrypcje, możesz odwołać w dowolnej chwilii z zachowniem 30-dniowego wypowiedzenia.<br />
                        </div>
                    </div>
                    <div className="register-form-container">
                        <SurveyCheckoutForm
                            location={location}
                            onSuccess={this.onSuccess} 
                        />
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
